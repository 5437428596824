<template>
    <div class="container">
        <div class="simple-page certify">
            <div class="page-title">音乐人认证</div>
            <div class="page-desc">请提供您具备音乐人能力的证明</div>
            <div class="form">
                <div class="form-item">
                    <div class="label">艺名</div>
                    <div class="value">
                        <input class="input" placeholder="请输入您的艺名" v-model="form.stageName" />
                    </div>
                </div>
                <div class="form-item">
                    <div class="label">个人主页</div>
                    <div class="value">
                        <div class="dropdown" @click="showDropList=!showDropList">
                            <img :src="platform.icon" class="icon" />
                            <img src="../assets/drop.svg" class="drop" />

                            <div class="droplist" v-if="showDropList">
                                <template v-for="item in platforms">
                                    <div class="item" :key="item.name" @click.stop.prevent="choosePlatform(item.name)">
                                        <img :src="item.icon" class="icon" />
                                        <div class="name">{{item.name}}</div>
                                        <img src="../assets/right.png" class="symbol" v-if="form.homePagePlatform == item.name" />
                                    </div>
                                </template>
                            </div>
                        </div>
                        <input class="input" placeholder="选择您的个人主页并填写地址" v-model="form.homepage" />
                    </div>
                    <div class="tips">*选填，填写将提高审核通过率。</div>
                </div>

                <div class="form-item">
                    <div class="label">作品</div>
                    <div class="value">
                        <input class="input" placeholder="请输入您的代表作" v-model="form.works" />
                    </div>
                </div>
              <div class="form-item">
                <div class="label">微信</div>
                <div class="value">
                  <input class="input" placeholder="请填写您的微信号" v-model="form.weChatNumber" />
                </div>
              </div>
              <div class="form-item">
                <div class="label">QQ</div>
                <div class="value">
                  <input class="input" placeholder="请填写您的QQ号" v-model="form.qqNumber" />
                </div>
              </div>
                <!--div class="form-item">
                    <div class="label">作品</div>
                    <div class="extra">
                        <div class="btn" v-if="!works">
                            <img src="../assets/upload.png" class="icon" />
                            上传
                            <input type="file" class="cover" />
                        </div>
                        <div class="works" v-else>
                            <div class="name">{{works.filename}}</div>
                            <div class="status">
                                <img src="../assets/success.png" class="icon" v-if="percent >= 100" />
                                <template v-if="percent < 100">
                                    {{percent}}%
                                </template>
                                <template v-else>上传成功</template>
                            </div>
                            <div class="del" v-if="works && works >= 100">
                                <img src="../assets/del.png" class="icon" @click="delWorks" />
                            </div>
                        </div>
                    </div>
                    <div class="tips">上传歌曲格式要求：200M以内，320kpbs以上，wav、mp3格式</div>
                </div-->

                <div class="form-item">
                    <div class="label">您担当的角色是</div>
                    <div class="extra">
                        <div class="radio-group">
                            <template v-for="item in roles">
                                <div class="radio" :key="item" :class="{checked: form.role.indexOf(item) > -1}" @click="selectRole(item)">{{item}}</div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="form-action">
                    <button @click="btn" class="btn">返 回</button>
                    <dbutton :disabled="false" @click="nextStep()">下一步</dbutton>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            platforms:[
                {
                    icon: '/assets/platforms/163.png',
                    name: '网易云音乐',
                },
                {
                    icon: '/assets/platforms/qq.png',
                    name: 'QQ音乐',
                },
                {
                    icon: '/assets/platforms/douyin.png',
                    name: '抖音',
                },
                {
                    icon: '/assets/platforms/bilibili.png',
                    name: 'bilibili',
                },
                {
                    icon: '/assets/platforms/quanming.png',
                    name: '全民K歌',
                },
                {
                    icon: '/assets/platforms/changba.png',
                    name: '唱吧',
                },
                {
                    icon: '/assets/platforms/5sing.png',
                    name: '5sing',
                },
            ],
            roles: ['演唱', '作词', '作曲'],
            showDropList: false,
            form:{
                stageName: '', // 艺名
                homePagePlatform: '', // 个人主页平台
                homepage: '', // 个人主页
                role: [], // 角色
                works: '', // 代表作
              qqNumber:'',//qq
              weChatNumber:''//微信
            },
            // 作品状态
            /*
            filename: '', 文件名
            percent: 上传进度
            */
            works: null

        }
    },
    computed:{
        // 当前选择的平台信息
        platform(){
            let obj = {}
            this.platforms.forEach(item => {
                if(item.name == this.form.homePagePlatform){
                    obj = item
                }
            })
            return obj
        }
    },
    created(){
        // this.$post('/api/user/userMsg').then((res) => {console.log(res)})
        // 默认选择第一个平台
        this.form.homePagePlatform = this.platforms[0].name
        if(localStorage['certifyData' + this.user.mobile]){
            let data = JSON.parse(localStorage['certifyData' + this.user.mobile])
            this.$set(this, 'form', data)
        }
    },
    methods:{
        selectRole(item){
            const index = this.form.role.indexOf(item)
            if (index > -1) {
                this.form.role.splice(index, 1)
            } else {
              this.form.role.push(item)
            }
        },
        nextStep(){
            let fieldsNames = {
                stageName: '艺名',
                // homePagePlatform: '个人主页平台',
                // homepage: '个人主页',
                // role: '角色',
                // works: '代表作'
            }
            let emptyFields = []
            for(let field in fieldsNames){
                if(this.form[field] == ''){
                    emptyFields.push(fieldsNames[field])
                }
            }
            if(emptyFields.length){
                return this.$toast(emptyFields.join(",") + "不能为空")
            }

            localStorage.setItem('certifyData' + this.user.mobile, JSON.stringify(this.form))
            this.$router.push("/verify")
        },
        /* showMessage(){
            console.log('aaaa')
            this.$message('消息提示啊', {time: 10000})
            //this.$refs.message.open('这里是消息内容啊')
            //this.$refs.message.open('这里是消息内容啊')
        }, */
        // 上传作品
        delWorks(){

        },
        choosePlatform(name){
            this.form.homePagePlatform = name
            this.showDropList = false
        },
        btn(){
            this.$router.replace("/")
        }

    }
}
</script>
<style lang="scss" scoped>
@import '../styles/simple-page.scss';
@import '../styles/form.scss';
.droplist{
    width: 164px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #EAEAEA;
    padding: 10px 15px;
    z-index: 2;
    position: absolute;
    top: 50px;
    right: 50%;
    transform: translateX(50%);
    /* TODO 这里还有一个小箭头没写，没对齐下拉箭头*/
    .item{
        padding: 10px 0;
        display: flex;
        align-items: center;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        cursor: pointer;
        .icon{
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
        .name{
            flex: 1;
        }
        .symbol{
            width: 9px;
            height: 9px;
            margin-left: 10px;
        }
    }
}
  .btn{
      width:202px;
      height:50px;
      margin-right:40px;
      background: #efefef;
      border: none;
      font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: CENTER;
    color: #ff004d;
    border-radius: 3px;
    }
</style>
